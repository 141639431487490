html,
body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  display: flex;
  justify-content: center;
  width: 100%;
}

#root {
  width: 100%;
  max-width: 1280px;
}
